





















export default {
  name : 'PageHeader',
  props : ['title','className'],
}
